import { AfterContentInit, Component, ContentChild, forwardRef, Input, TemplateRef } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';
@Component({
  selector: 'fullstack-form-row-list',
  templateUrl: './form-row-list.component.html',
  styleUrls: ['./form-row-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRowListComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS, // Is an InjectionToken required by this class to be able to be used as an Validator
      useExisting: forwardRef(() => FormRowListComponent),
      multi: true,
    },
  ],
})
export class FormRowListComponent extends FormElement implements AfterContentInit {
  @Input() title;
  @Input() appendOnly = false;
  @Input() controlsFn;
  @ContentChild('rowTemplate', { read: TemplateRef }) rowTemplate: TemplateRef<any>;
  formArray: UntypedFormArray;
  _validationChange = () => {};

  constructor(private fb: UntypedFormBuilder) {
    super();
    this.formArray = this.fb.array([]);
    this.form = this.formArray;
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this._validationChange();
    });
    this.formArray.valueChanges.subscribe((v) => {
      this._onChange(v);
      this._validationChange();
    });
  }

  writeValue(value) {
    this.formArray.controls = [];
    if (value) {
      value.forEach(() => this.addRow());
      this.formArray.patchValue(value, { onlySelf: true, emitEvent: false });
    }
  }

  addRow() {
    this.formArray.push(this.controlsFn(), { emitEvent: false });
  }

  removeRow(index) {
    this.formArray.removeAt(index);
  }
}
