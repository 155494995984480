/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IAddress, IContactIndividual } from '@renovars/common';
import { ArchitectCodes, ICandidate } from '@renovars/common/recruit';
import { AuthServices } from '@renovars/core-ng';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import { MessageService } from 'primeng/api';
import { concatAll, filter, Observable, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'facile-candidati-details-fr-architet-info',
  template: `
    <facile-candidati-recap [candidate]="candidato"></facile-candidati-recap>
    <div>
      <div class="row">
        <div class="col-12">
          <header class="page-header">
            <h2 class="tx-title-2">Dati Addizionali</h2>
          </header>
        </div>
        <div class="col mt-1">
          <fullstack-input-check-box
            [ngModel]="stateOnFr.isAvailable"
            (ngModelChange)="toogleState($event)"
            [options]="{ label: 'Disponibile' }"
          ></fullstack-input-check-box>
        </div>
        <div class="col mt-1">
          <fullstack-input-check-box
            [ngModel]="stateOnFr.busy"
            [options]="{ label: 'Occupato' }"
            [disabled]="disabled"
          ></fullstack-input-check-box>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <fullstack-input-box
            [options]="{ label: 'Username *' }"
            [(ngModel)]="username"
            [disabled]="disabled"
          ></fullstack-input-box>
        </div>
        <div class="col">
          <fullstack-input-box
            [options]="{ label: 'Email Aziendale *' }"
            [(ngModel)]="workingEmail"
            [disabled]="disabled"
          ></fullstack-input-box>
        </div>
        <div class="col">
          <fullstack-input-box
            [options]="{ label: 'Raggio *' }"
            [(ngModel)]="radius"
            [disabled]="disabled"
          ></fullstack-input-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <fullstack-input-select-box
            [options]="{ label: 'Tipologia *' }"
            [(ngModel)]="architectType"
            [disabled]="disabled"
          >
            <sb-option [value]="'Architetto'">Architetto</sb-option>
            <sb-option [value]="'Geometra'">Geometra</sb-option>
            <sb-option [value]="'Ingegnere'">Ingegnere</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col">
          <fullstack-input-address-box
            [options]="{ label: 'Indirizzo Operativo su FR *' }"
            [(ngModel)]="frAddress"
          ></fullstack-input-address-box>
        </div>
        <div class="col">
          <fullstack-input-select-box
            [ngModel]="shop"
            (ngModelChange)="shop = $event"
            [options]="{ label: 'Shop *' }"
            [disabled]="disabled"
          >
            <sb-option [key]="'code'" [value]="i" *ngFor="let i of shops | async">{{ i.value }}</sb-option>
          </fullstack-input-select-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <fullstack-input-select-box
            [ngModel]="parent"
            (ngModelChange)="parent = $event; withParent()"
            [options]="{ label: 'Superiore *' }"
            [disabled]="disabled"
          >
            <sb-option [key]="'code'" [value]="i" *ngFor="let i of frStoreManagers | async">{{
              i.value
            }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col">
          <fullstack-input-box
            [options]="{ label: 'AM' }"
            [(ngModel)]="areaManager"
            [disabled]="true"
          ></fullstack-input-box>
        </div>
        <div class="col">
          <fullstack-input-box
            [options]="{ label: 'DC' }"
            [(ngModel)]="dc"
            [disabled]="true"
          ></fullstack-input-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <fullstack-input-textarea
            [options]="{ label: 'Note', rows: 4 }"
            [(ngModel)]="note"
            [disabled]="disabled"
          ></fullstack-input-textarea>
        </div>
      </div>
      <div class="row mt-2">
        <div class="text-end">
          <div class="col">
            <button
              (click)="savePartial()"
              class="btn btn-secondary"
              [disabled]="candidato?.crmFrData?.crmFrId"
            >
              Salva
            </button>
          </div>
          <div class="col mt-2" *permissions="['is_admin']">
            <button (click)="activate()" class="btn btn-secondary" [disabled]="true">Attiva su FR</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class CandidateDetailsFrArchitectInfoComponent implements OnChanges {
  statusCode: string;
  @Input() candidato: ICandidate;
  @Input() disabled = false;
  parent: string;
  shop: string;
  username: string;
  workingEmail: string;
  stateOnFr: { isAvailable: boolean; busy: boolean } = { isAvailable: false, busy: false };
  frAddress: IAddress;
  frType: string;
  radius: number;
  note: string;
  shops: Observable<{ value: string; code: string }[]>;
  frStoreManagers: Observable<{ value: string; code: string; email: string; ancestors: string[] }[]>;
  architectType: 'Architetto' | 'Geometra' | 'Ingegnere' = 'Architetto';
  areaManager: string;
  dc: string;
  constructor(
    private candidateService: CandidatesServices,
    private messageService: MessageService,
    private authService: AuthServices
  ) {}
  ngOnChanges(): void {
    const contact = this.candidato?.cache?.contact as IContactIndividual;
    this.parent = this.candidato?.crmFrData?.registration?.parent;
    this.shop = this.candidato?.crmFrData?.registration?.shop;
    this.username =
      this.candidato?.crmFrData?.registration?.username ||
      contact.firstName.trim().toLowerCase().replace(' ', '') +
        '.' +
        contact.lastName.trim().toLowerCase().replace(' ', '') ||
      null;
    this.workingEmail = this.candidato?.crmFrData?.registration?.workingEmail || null;
    this.frType = this.candidato?.crmFrData?.registration?.type || null;
    this.radius = this.candidato?.crmFrData?.registration?.radius || null;
    this.note = this.candidato?.crmFrData?.registration?.note || null;
    this.shops = this.candidateService.getFrShop$();
    this.frStoreManagers = this.candidateService.getFrSM$();
    this.architectType = this.candidato?.crmFrData?.registration?.typology || 'Architetto';
    this.frAddress = this.candidato?.crmFrData?.registration?.frAddress || null;
    this.note = this.candidato?.crmFrData?.registration?.note || null;
    if (this.candidato?.crmFrData?.crmFrId) {
      this.candidateService
        .getFrUserById$(this.candidato?.crmFrData?.crmFrId)
        .pipe(
          concatAll(),
          tap((v: any) => {
            this.stateOnFr.isAvailable = v.isAvailable;
            this.stateOnFr.busy = v.busy;
          })
        )
        .subscribe();
    }
    if (!this.parent) {
      this.withoutParent();
    } else {
      this.withParent();
    }
  }
  withParent() {
    of(this.parent)
      .pipe(
        switchMap((id) =>
          this.candidateService.getFrSM$().pipe(
            concatAll(),
            filter((sm) => sm.code === id),
            switchMap((sm) => this.candidateService.getFrAnchestors$(sm.ancestors)),
            tap((ar) => {
              this.areaManager = ar?.find((e) => e.value.includes('AM'))?.value
                ? ar?.find((e) => e.value.includes('AM'))?.value
                : '';
              this.dc = ar?.find((e) => e.value.includes('DC'))?.value
                ? ar?.find((e) => e.value.includes('DC'))?.value
                : '';
            })
          )
        )
      )
      .subscribe();
  }
  private withoutParent() {
    this.authService
      .user()
      .pipe(
        switchMap((user) =>
          this.candidateService.getFrSM$().pipe(
            concatAll(),
            filter((sm) => sm.email === user.email),
            tap((sm) => {
              if (sm.email === user.email) {
                this.parent = sm.code;
              }
            }),
            switchMap((sm) => this.candidateService.getFrAnchestors$(sm.ancestors)),
            tap((ar) => {
              this.areaManager = ar?.find((e) => e.value.includes('AM'))?.value
                ? ar?.find((e) => e.value.includes('AM'))?.value
                : '';
              this.dc = ar?.find((e) => e.value.includes('DC'))?.value
                ? ar?.find((e) => e.value.includes('DC'))?.value
                : '';
            })
          )
        )
      )
      .subscribe();
  }

  savePartial() {
    const body: ICandidate = {
      ...this.candidato,
      crmFrData: {
        registration: {
          username: this.username,
          workingEmail: this.workingEmail,
          type: this.frType,
          radius: this.radius,
          shop: this.shop,
          parent: this.parent,
          typology: this.architectType,
          frAddress: this.frAddress,
          note: this.note,
        },
      },
      status: {
        ...this.candidato.status,
        code: ArchitectCodes.SM_DA_ATTIVARE_FR,
      },
    };
    this.candidateService.savePartialFrArchitect(body).subscribe();
  }
  activate() {
    const body: ICandidate = {
      ...this.candidato,
      crmFrData: {
        registration: {
          username: this.username,
          workingEmail: this.workingEmail,
          type: this.frType,
          radius: this.radius,
          shop: this.shop,
          parent: this.parent,
          typology: this.architectType,
          frAddress: this.frAddress,
          note: this.note,
        },
      },
    };
    this.candidateService.createFrArchitect(body).subscribe({
      next: () => {},
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Creazione Fr',
          detail: err?.error?.message,
        });
      },
    });
  }
  toogleState($event: boolean) {
    const payload = $event ? { isAvailable: true } : { busy: true };
    this.stateOnFr = {
      isAvailable: $event,
      busy: !$event,
    };
    this.candidateService.setFrArchitectState(this.candidato.crmFrData.crmFrId, payload).subscribe();
  }
}
