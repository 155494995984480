import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateType, ICandidate } from '@renovars/common/recruit';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import { filter } from 'rxjs';

@Component({
  selector: 'facile-candidati-details-fr-info',
  template: `
    <div class="p-2">
      <facile-candidati-details-fr-worker-info
        [candidato]="candidato"
        *ngIf="candidato && candidato.type === candidateType.WORKER"
      ></facile-candidati-details-fr-worker-info>
      <facile-candidati-details-fr-architet-info
        [candidato]="candidato"
        *ngIf="candidato && candidato.type === candidateType.ARCHITECT"
      ></facile-candidati-details-fr-architet-info>
      <facile-candidati-details-fr-assistant-info
        [candidato]="candidato"
        *ngIf="candidato && candidato.type === candidateType.TO_SKILL"
      ></facile-candidati-details-fr-assistant-info>
    </div>
  `,
  styles: [],
})
export class CandidatiDetailsFrInfoComponent implements OnInit {
  candidato: ICandidate;
  candidateType = CandidateType;
  constructor(private candidateService: CandidatesServices, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.candidateService
          .getCandidate(id)
          .pipe(filter((candidate) => candidate !== null))
          .subscribe((candidate) => {
            this.candidato = candidate;
          });
      }
    });
  }
}
