import { Component, Input, OnInit } from '@angular/core';
import { IAddress, IContactIndividual } from '@renovars/common';
import { ICandidate, IFRSearchResult } from '@renovars/common/recruit';
import { IFileServerModel } from '@renovars/core-nest';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import { environment } from 'apps/recruit-ng/src/environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'facile-candidati-details-fr-assistant-info',
  template: `
    <div mat-dialog-content>
      <facile-candidati-recap [candidate]="candidato"></facile-candidati-recap>
      <div class="row">
        <div class="col-12">
          <header class="page-header">
            <h2 class="tx-title-2">Dati Addizionali</h2>
          </header>
        </div>
      </div>

      <div class="row mt-2" *permissions="['is_admin']">
        <div class="col">
          <fullstack-input-box
            [options]="{ label: 'Username *' }"
            [(ngModel)]="username"
          ></fullstack-input-box>
        </div>
        <div class="col">
          <fullstack-input-box
            [options]="{ label: 'Email Aziendale *' }"
            [(ngModel)]="workingEmail"
          ></fullstack-input-box>
        </div>
        <div class="col">
          <fullstack-input-box [options]="{ label: 'Raggio *' }" [(ngModel)]="radius"></fullstack-input-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <fullstack-input-address-box
            [options]="{ label: 'Indirizzo Operativo su FR *' }"
            [(ngModel)]="frAddress"
            (ngModelChange)="frAddress = $event; findSuperior($event)"
          ></fullstack-input-address-box>
        </div>
        <div class="col">
          <fullstack-input-select-box
            [ngModel]="shop"
            (ngModelChange)="shop = $event"
            [options]="{ label: 'Shop *' }"
          >
            <sb-option [key]="'code'" [value]="i" *ngFor="let i of shops | async">{{ i.value }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col">
          <fullstack-input-select-box
            [ngModel]="parent"
            (ngModelChange)="parent = $event"
            [options]="{ label: 'SM *' }"
          >
            <sb-option [key]="'code'" [value]="i" *ngFor="let i of frStoreManagers | async">{{
              i.value
            }}</sb-option>
          </fullstack-input-select-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <fullstack-input-select-box [options]="{ label: 'Tipologia *' }" [(ngModel)]="architectType">
            <sb-option [value]="'Architetto'">Architetto</sb-option>
            <sb-option [value]="'Geometra'">Geometra</sb-option>
            <sb-option [value]="'Ingegnere'">Ingegnere</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col">
          <fullstack-input-select-box
            [ngModel]="senior"
            (ngModelChange)="senior = $event"
            [options]="{ label: 'Senior *' }"
          >
            <sb-option [key]="'code'" [value]="i" *ngFor="let i of seniors | async">{{ i.value }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col">
          <fullstack-datepicker
            [ngModel]="assistantEmploymentDate"
            (ngModelChange)="assistantEmploymentDate = $event"
            [options]="{ label: 'Data assunzione *' }"
          ></fullstack-datepicker>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <fullstack-input-textarea
            [options]="{ label: 'Note', rows: 2 }"
            [(ngModel)]="note"
          ></fullstack-input-textarea>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-sm-12">
          <fullstack-file-upload-new
            [options]="{ label: 'Files' }"
            [accept]="'application/pdf'"
            [multiple]="true"
            [fileList]="attachmentFiles"
            (onFilesSelect)="onAttSelect($event)"
            [storageUrl]="environment.apiGatewayURL + '/recruit/file-server/download'"
          >
            <ng-template file-row let-file>
              <div class="d-flex align-items-center">
                <div class="p-1 flex-fill">
                  <fullstack-input-box
                    [options]="{ label: 'Nome file' }"
                    [ngModel]="file.metadata.name || file.originalName"
                    (ngModelChange)="file.metadata.name = $event"
                    [ngModelOptions]="{ standalone: true }"
                  ></fullstack-input-box>
                </div>
              </div>
            </ng-template>
          </fullstack-file-upload-new>
        </div>
      </div>
    </div>
    <div class="row mt-2" mat-dialog-actions>
      <div class="text-end">
        <div class="col">
          <button
            (click)="salva()"
            class="btn btn-secondary"
            [disabled]="
              disabled &&
              (candidato?.crmFrData?.crmFrId || !frAddress || !attachmentFiles || attachmentFiles.length == 0)
            "
          >
            Salva
          </button>
        </div>
        <div class="col mt-2" *permissions="['is_admin']">
          <button
            (click)="activate()"
            class="btn btn-secondary"
            [disabled]="
              disabled &&
              (candidato?.crmFrData?.crmFrId ||
                !frAddress ||
                !senior ||
                !attachmentFiles ||
                !parent ||
                !shop ||
                attachmentFiles.length == 0)
            "
          >
            Attiva su FR
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class CandidateDetailsFrAssistantInfoComponent implements OnInit {
  environment = environment;
  attachmentFiles: IFileServerModel[] = [];
  @Input() candidato: ICandidate;
  disabled: boolean;
  parent: string;
  senior: any;
  shop: string;
  username: string;
  workingEmail: string;
  stateOnFr: { isAvailable: boolean; busy: boolean } = { isAvailable: false, busy: false };
  frAddress: IAddress;
  frType: string;
  radius: number;
  note: string;
  shops: Observable<{ value: string; code: string }[]>;
  frStoreManagers: Observable<IFRSearchResult[]>;
  seniors: Observable<Pick<IFRSearchResult, 'code' | 'email' | 'value'>[]>;
  architectType: 'Architetto' | 'Geometra' | 'Ingegnere' = 'Architetto';
  areaManager: string;
  dc: string;
  assistantEmploymentDate: Date;
  constructor(private candidateService: CandidatesServices) {}

  ngOnInit(): void {
    const contact = this.candidato?.cache?.contact as IContactIndividual;
    this.parent = this.candidato?.crmFrData?.registration?.parent;
    this.shop = this.candidato?.crmFrData?.registration?.shop;
    this.username =
      this.candidato?.crmFrData?.registration?.username ||
      contact.firstName.trim().toLowerCase().replace(' ', '') +
        '.' +
        contact.lastName.trim().toLowerCase().replace(' ', '') ||
      null;
    this.workingEmail = this.candidato?.crmFrData?.registration?.workingEmail || null;
    this.frType = this.candidato?.crmFrData?.registration?.type || null;
    this.radius = this.candidato?.crmFrData?.registration?.radius || null;
    this.note = this.candidato?.crmFrData?.registration?.note || null;
    this.shops = this.candidateService.getFrShop$();
    this.frStoreManagers = this.candidateService.getFrSM$();
    this.architectType = this.candidato?.crmFrData?.registration?.typology || 'Architetto';
    this.frAddress = this.candidato?.crmFrData?.registration?.frAddress || null;
    this.note = this.candidato?.crmFrData?.registration?.note || null;
    this.senior = this.candidato?.crmFrData?.registration?.senior || null;
    this.assistantEmploymentDate = this.candidato?.crmFrData?.registration?.assistantEmploymentDate || null;
    if (this.candidato?.crmFrData?.registration?.contract) {
      this.candidateService
        .getContract(this.candidato.crmFrData.registration.contract)
        .subscribe((file) => (this.attachmentFiles = [file]));
    }
    if (this.frAddress) {
      this.findSuperior(this.frAddress);
    }
  }
  salva() {
    const body: ICandidate = {
      ...this.candidato,
      crmFrData: {
        registration: {
          username: this.username,
          workingEmail: this.workingEmail,
          type: this.frType,
          radius: this.radius,
          shop: this.shop,
          parent: this.parent,
          typology: this.architectType,
          frAddress: this.frAddress,
          note: this.note,
          contract: this.attachmentFiles[0].id,
          senior: this.senior && typeof this.senior === 'string' ? this.senior : this.senior?.code,
          assistantEmploymentDate: this.assistantEmploymentDate,
        },
      },
    };
    this.candidateService.savePartialFrArchitectWithSenior(body).subscribe();
  }
  activate() {
    const body: ICandidate = {
      ...this.candidato,
      crmFrData: {
        registration: {
          username: this.username,
          workingEmail: this.workingEmail,
          type: this.frType,
          radius: this.radius,
          shop: this.shop,
          parent: this.parent,
          typology: this.architectType,
          frAddress: this.frAddress,
          note: this.note,
          contract: this.attachmentFiles[0].id,
          senior: typeof this.senior === 'string' ? this.senior : this.senior.code,
          assistantEmploymentDate: this.assistantEmploymentDate,
        },
      },
    };
    this.candidateService.createFrArchitectWithSenior(body).subscribe();
  }
  onAttSelect(event) {
    const files: FileList = event.target.files;
    this.candidateService.addFile(files).subscribe((files: IFileServerModel<any>[]) => {
      this.attachmentFiles = [...files, ...this.attachmentFiles];
    });
  }
  findSuperior(event: IAddress) {
    this.seniors = this.candidateService.getFrSeniorArchitect$(event.coords as [number, number]);
  }
}
