import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AllStatusCodes, CandidateType } from '@renovars/common/recruit';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import { map, switchMap } from 'rxjs';

@Component({
  selector: 'facile-candidati-details',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">Candidato {{ candidateTypeLabel }}</h1>
    </ng-template>
    <ng-template header-menu>
      <fullstack-tabs>
        <tab *ngIf="id" [label]="'Anagrafica'" [url]="'./anag-info' + queryString"></tab>
        <tab [label]="'Dettaglio'" [url]="'./main-info' + queryString"></tab>
        <tab *ngIf="id" [label]="'Profilo'" [url]="'./profile-info' + queryString"></tab>
        <tab
          *ngIf="
            id &&
            (status === codes.SM_OPERATIVO ||
              status === codes.SM_DA_ATTIVARE_FR ||
              status === codes.HS_POST_OK_AFFIANCAMENTO ||
              status === codes.SM_ATTIVATO_FR)
          "
          [label]="'FR'"
          [url]="'./fr-info' + queryString"
        ></tab>
      </fullstack-tabs>
    </ng-template>
    <router-outlet></router-outlet>
  `,
})
export class CandidatiDetailsComponent implements OnInit {
  public id: string;
  public queryString = '';
  codes = AllStatusCodes;
  status: keyof typeof AllStatusCodes = null;
  candidateType: CandidateType;
  candidateTypeLabel: string;

  constructor(private activatedRoute: ActivatedRoute, private candidatesService: CandidatesServices) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.candidateType = res.candidateType as CandidateType;
      this.candidateTypeLabel = CandidateType.toLabel(this.candidateType);
    });

    if (this.activatedRoute.firstChild) {
      this.activatedRoute.firstChild.paramMap
        .pipe(
          map((params) => {
            this.id = params.get('id');
            if (this.id) {
              this.queryString = `/${this.id}`;
            }
            return this.id;
          }),
          switchMap((id) => this.candidatesService.getCandidate(id))
        )
        .subscribe((candidate) => {
          this.status = candidate.status.code as keyof typeof AllStatusCodes;
        });
    }
  }
}
