<div class="row">
  <div class="col-12">
    <header class="page-header border-bottom-0">
      <div class="row align-items-center">
        <div class="col">
          <h2 class="tx-title-3">{{ title }}</h2>
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-header" (click)="addRow()">
            <i class="fas fa-plus cl-primary fa-1x"></i>
          </button>
        </div>
      </div>
    </header>
  </div>
</div>

<div>
  <div class="d-flex" *ngFor="let elem of formArray.controls; let i = index">
    <div class="flex-grow-1">
      <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: elem }"></ng-container>
    </div>
    <div class="remove-button ms-2" (click)="removeRow(i)" *ngIf="!appendOnly">
      <i class="fas fa-times-circle"></i>
    </div>
  </div>
</div>
