import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { ContactModule, SiteModule, UtilsService } from '@renovars/core-ng';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TimelineModule } from 'primeng/timeline';
import { CandidatesServices } from '../../core/services/candidates.services';
import { CsvUtilsService } from '../../core/services/csv-utils.service';
import { SharedModule } from '../../shared/shared.module';
import { AppointmentWrapModule } from '../appointment/appointment-wrap.module';
import { CandidatiRoutingModule } from './candidati-routing.module';
import { CandidatiImpresaAllInfoFormComponent } from './components/candidati-impresa/candidati-impresa-all-info-form.component';
import { CandidatiImpresaAnagInfoFormComponent } from './components/candidati-impresa/candidati-impresa-anag-info-form.component';
import { CandidatiImpresaMainInfoFormComponent } from './components/candidati-impresa/candidati-impresa-main-info-form.component';
import { CandidatiImpresaProfileInfoFormComponent } from './components/candidati-impresa/candidati-impresa-profile-info-form.component';
import { CandidatiAllInfoFormComponent } from './components/candidati-individuo/candidati-all-info-form.component';
import { CandidatiAnagInfoFormComponent } from './components/candidati-individuo/candidati-anag-info-form.component';
import { CandidatiMainInfoFormComponent } from './components/candidati-individuo/candidati-main-info-form.component';
import { CandidatiProfileInfoFormComponent } from './components/candidati-individuo/candidati-profile-info-form.component';
import { CandidatiComponent } from './components/candidati.component';
import {
  ModalCandidatiStatoAppointmentComponent,
  ModalCandidatiStatoComponent,
  ModalCandidatiStatoDirectComponent,
} from './components/modal-candidati-stato/modal-candidati-stato.component';
import { CandidatiDetailsAnagInfoComponent } from './details/components/candidati-details-anag-info.component';
import { CandidatiDetailsMainInfoComponent } from './details/components/candidati-details-main-info.component';
import { CandidatiDetailsProfileInfoComponent } from './details/components/candidati-details-profile-info.component';
import { CandidatiDetailsTimelineComponent } from './details/components/candidati-details-timeline.component';
import { CandidatiDetailsComponent } from './details/components/candidati-details.component';
import { CandidatiAllInfoComponent } from './upsert/components/candidati-all-info.component';
import { CandidatiAnagInfoComponent } from './upsert/components/candidati-anag-info.component';
import { CandidatiMainInfoComponent } from './upsert/components/candidati-main-info.component';
import { CandidatiProfileInfoComponent } from './upsert/components/candidati-profile-info.component';
import { CandidatiUpsertComponent } from './upsert/components/candidati-upsert.component';
import { ModalFrActivationComponent } from './components/modal-candidati-stato/modal-fr-activation.component';
import { CandidatiRecapComponent } from './components/candidati-recap/candidati-recap.component';
import { UserService } from '../../core/services/user.service';
import { RecuitUsersServices } from '../../core/services/recuit-users-services.service';
import { CandidatiFilterService } from './services/candidati-filter.service';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/recruit-ng/src/environments/environment';
import { ModalTrainingComponent } from './components/modal-candidati-stato/modal-training.component';
import { CandidateSourceComponent } from './components/candidate-source/candidate-source.component';
import { ModalHrSpecialistComponent } from './components/modal-candidati-stato/modal-hr-specialist.component';
import { ModalFrSeniorActivationComponent } from './components/modal-candidati-stato/modal-fr-senior-activation.component';
import { ArchitectComponent } from './components/architect.component';
import { CompanyComponent } from './components/company.component';
import { WorkerComponent } from './components/worker.component';
import { ToSkillComponent } from './components/to-skill.component';
import { ModalCompanyAppointmentComponent } from './components/modal-candidati-stato/modal-company-appointment.component';
import { ModalAssingUserComponent } from './components/modal-candidati-stato/modal-assing-user.component';
import { CandidatiDetailsFrInfoComponent } from './details/components/candidati-details-fr-info.component';
import { CandidatiDetailsFrWorkerInfoComponent } from './details/components/fr-integration/candidati-details-fr-worker-info.component';
import { CandidateDetailsFrArchitectInfoComponent } from './details/components/fr-integration/candidati-details-fr-architect-info.component';
import { CandidateDetailsFrAssistantInfoComponent } from './details/components/fr-integration/candidati-details-fr-assistant-info.component';
@NgModule({
  declarations: [
    CandidatiComponent,
    CandidatiUpsertComponent,
    CandidatiDetailsComponent,
    ModalCandidatiStatoComponent,
    ModalCandidatiStatoAppointmentComponent,
    ModalCandidatiStatoDirectComponent,
    CandidatiMainInfoComponent,
    CandidatiProfileInfoComponent,
    CandidatiDetailsProfileInfoComponent,
    CandidatiDetailsMainInfoComponent,
    CandidatiDetailsAnagInfoComponent,
    CandidatiMainInfoFormComponent,
    CandidatiProfileInfoFormComponent,
    CandidatiImpresaMainInfoFormComponent,
    CandidatiImpresaProfileInfoFormComponent,
    CandidatiDetailsTimelineComponent,
    CandidatiAnagInfoComponent,
    CandidatiImpresaAnagInfoFormComponent,
    CandidatiAnagInfoFormComponent,
    CandidatiAllInfoComponent,
    CandidatiAllInfoFormComponent,
    CandidatiImpresaAllInfoFormComponent,
    ModalFrActivationComponent,
    CandidatiRecapComponent,
    ModalTrainingComponent,
    CandidateSourceComponent,
    ModalHrSpecialistComponent,
    ModalFrSeniorActivationComponent,
    ModalCompanyAppointmentComponent,
    ArchitectComponent,
    CompanyComponent,
    WorkerComponent,
    ToSkillComponent,
    ModalAssingUserComponent,
    CandidatiDetailsFrInfoComponent,
    CandidatiDetailsFrWorkerInfoComponent,
    CandidateDetailsFrArchitectInfoComponent,
    CandidateDetailsFrAssistantInfoComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    CandidatiRoutingModule,
    TimelineModule,
    AppointmentWrapModule,
    MatMenuModule,
    ConfirmDialogModule,
    RadioButtonModule,
    SiteModule.forRoot({
      url: `${environment.apiGatewayURL}/recruit`,
      exclude: {
        bcCode: true,
        phone: true,
      },
    }),
    ContactModule,
  ],
  providers: [
    ConfirmationService,
    CsvUtilsService,
    CandidatesServices,
    UtilsService,
    UserService,
    RecuitUsersServices,
    CandidatiFilterService,
  ],
})
export class CandidatiModule {}
