/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type AvailabilityResponseBody, type IEvent, type IUser } from '@renovars/common';
import {
  AllStatusCodes,
  ArchitectCodes,
  CandidateType,
  ICandidate,
  IRecruitEvent,
  RecruitEventType,
  RecruitRoles,
  StatusCodesLabel,
} from '@renovars/common/recruit';
import { EventService } from '@renovars/core-ng';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import { UserService } from 'apps/recruit-ng/src/app/core/services/user.service';
import * as _ from 'lodash';
import { Observable, forkJoin, switchMap, zip } from 'rxjs';
import { buildIndividualMailText } from './mail-text.utils';

enum ModalChoice {
  ASSEGNAZIONE = 'assegnazione',
  APPUNTAMENTO = 'appuntamento',
}
@Component({
  selector: 'facile-modal-candidati-stato',
  templateUrl: './modal-candidati-stato.component.html',
  styles: [],
})
export class ModalCandidatiStatoComponent implements OnInit {
  choice: ModalChoice = ModalChoice.ASSEGNAZIONE;
  statusCode: string;
  candidato: ICandidate;
  statuses = AllStatusCodes;
  CandidateType = CandidateType;

  constructor(
    public dialogRef: MatDialogRef<ModalCandidatiStatoDirectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { statusCode: string; candidato: ICandidate }
  ) {}
  ngOnInit(): void {
    this.statusCode = this.data.statusCode;
    this.candidato = this.data.candidato;
  }

  close(event) {
    this.dialogRef.close(event);
  }
}

@Component({
  selector: 'facile-modal-candidati-stato-appointment',
  templateUrl: './modal-candidati-stato-appointment.html',
  styles: [
    `
      .mat-dialog-content {
        overflow: visible;
      }
    `,
  ],
})
export class ModalCandidatiStatoAppointmentComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() candidato: ICandidate;
  fb: IFormBuilder = new UntypedFormBuilder();
  formAppointment: IFormGroup<Partial<IRecruitEvent>>;
  slotList: AvailabilityResponseBody[];
  range: { start: Date; end: Date };
  slot: UntypedFormControl;
  constructor(private eventService: EventService, private userService: UsersService) {}

  ngOnInit(): void {
    this.formAppointment = this.fb.group<Partial<IRecruitEvent>>({
      contact: new UntypedFormControl(this.candidato.contact, []),
      candidate: new UntypedFormControl(this.candidato.id, []),
      start: new UntypedFormControl('', []),
      end: new UntypedFormControl('', []),
      address: new UntypedFormControl(this.candidato.addressOp || '', []),
      user: new UntypedFormControl('', []),
      note: new UntypedFormControl('', []),
      mailVisible: new UntypedFormControl(false, []),
      sendMail: new UntypedFormControl(true, []),
      mailText: new UntypedFormControl('', []),
    });
    this.slot = new UntypedFormControl({}, []);
    this.formAppointment
      .get('mailVisible')
      .valueChanges.pipe(
        switchMap(() => {
          const event = this.buildEvent();
          return zip([this.userService.getUser(event.user), this.userService.getUserMeta(event.user)]);
        })
      )
      .subscribe(([user, meta]) => {
        const event = this.buildEvent();
        const text = buildIndividualMailText(this.candidato, user, meta, event);
        this.formAppointment.get('mailText').setValue(text);
      });
  }

  checkEventAvailability() {
    const eventForm = _.cloneDeep(this.formAppointment.value);

    if (eventForm.address && this.range && this.range.start && this.range.end) {
      const request: Partial<IEvent> = {
        start: this.range.start,
        end: this.range.end,
        address: eventForm.address,
      };

      this.eventService.checkEventAvailability(request).subscribe((res) => (this.slotList = res));
    }
  }
  save() {
    if (this.formAppointment.valid && this.slot.valid) {
      const eventForm = this.buildEvent();
      this.eventService.create(eventForm).subscribe();
      this.closeModal.emit();
    }
  }
  private buildEvent() {
    const eventForm = _.cloneDeep(this.formAppointment.value);
    const slot: { account; slot } = this.slot.value;
    eventForm.start = new Date(slot.slot.start);
    eventForm.end = new Date(slot.slot.end);
    eventForm.user = slot.account.id;
    eventForm.type = RecruitEventType.APPUNTAMENTO;
    eventForm.contact = typeof eventForm.contact === 'string' ? eventForm.contact : eventForm.contact._id;
    eventForm.mailText = eventForm.mailText ? eventForm.mailText.split('\n').join('<br/>') : null;
    eventForm.candidate = this.candidato?.id || this.candidato?._id;
    return eventForm;
  }
}

@Component({
  selector: 'facile-modal-candidati-stato-direct',
  templateUrl: './modal-candidati-stato-direct.html',
  styles: [
    `
      .mat-dialog-content {
        overflow: visible;
      }
    `,
  ],
})
export class ModalCandidatiStatoDirectComponent implements OnInit {
  StatusCodesLabel = StatusCodesLabel;
  StatusCodes = AllStatusCodes;

  note: string = null;
  date: string = null;
  assigned: string = null;
  storeManagersAvailable$: Observable<IUser[]>;
  selectableHr$: Observable<IUser[]>;

  @Input() statusCode: string;
  @Input() candidato: ICandidate;
  @Output() closeModal = new EventEmitter();

  constructor(private usersService: UserService, private candiateService: CandidatesServices) {}

  ngOnInit(): void {
    if (this.statusCode === ArchitectCodes.HR_ASSEGNATO) {
      this.storeManagersAvailable$ = this.candiateService.getStoreManagersAvailable(this.candidato.id);
      this.assigned = this.candidato && this.candidato.assigned ? this.candidato.assigned.toString() : null;
    }
    if (this.statusCode === ArchitectCodes.ADMIN_ASSEGNATO) {
      this.selectableHr$ = this.usersService.getUsersByRole(RecruitRoles.HR_OFFICE);
    }
    if (this.statusCode === ArchitectCodes.HS_PRE_OK_ASSEGNA_ADMIN) {
      this.selectableHr$ = this.usersService.getUsersByRole(RecruitRoles.ADMIN);
    }
    if (this.candidato.status.code === ArchitectCodes.SM_ATTIVATO_FR) {
      this.closeModal.emit({ error: 'Non è possibile cambiare stato ad un candidato attivo su FR' });
    }
  }

  annulla(): void {
    this.closeModal.emit();
  }
  invalidDate() {
    return this.statusCode === ArchitectCodes.SM_AFFIANCAMENTO_FISSATO && !this.date;
  }
  salva(formValue): void {
    if (this.invalidDate()) return;
    if (this.statusCode === ArchitectCodes.HR_ASSEGNATO) {
      this.candiateService.setCandidateStoreManager(this.candidato.id, this.assigned).subscribe();
    }
    if (this.statusCode === ArchitectCodes.ADMIN_ASSEGNATO) {
      this.candiateService.setHr(this.candidato.id, this.assigned).subscribe(console.log);
    }
    if (this.statusCode === ArchitectCodes.HS_PRE_OK_ASSEGNA_ADMIN) {
      forkJoin([
        this.candiateService.setCandidateStoreManager(this.candidato.id, this.assigned),
        this.candiateService.setHr(this.candidato.id, this.assigned),
      ]).subscribe(console.log);
    }
    this.closeModal.emit({
      candidato: this.candidato,
      status: { code: this.statusCode, data: formValue },
    });
  }
}
