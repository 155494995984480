import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IAddress, IContactIndividual } from '@renovars/common';
import { ICandidate, IFRSearchResult } from '@renovars/common/recruit';
import { IFileServerModel } from '@renovars/core-nest';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import { environment } from 'apps/recruit-ng/src/environments/environment';
import { Observable, filter } from 'rxjs';

@Component({
  selector: 'facile-candidati-details-fr-worker-info',
  template: `
    <div class="p-2">
      <facile-candidati-recap [candidate]="candidato"></facile-candidati-recap>
      <div class="row">
        <div class="col-12">
          <header class="page-header">
            <h2 class="tx-title-2">Dati Addizionali</h2>
          </header>
        </div>
      </div>

      <div class="row mt-2" *permissions="['is_admin']">
        <div class="col">
          <fullstack-input-box
            [options]="{ label: 'Username *' }"
            [(ngModel)]="username"
          ></fullstack-input-box>
        </div>
        <div class="col">
          <fullstack-input-box
            [options]="{ label: 'Email Aziendale *' }"
            [(ngModel)]="workingEmail"
          ></fullstack-input-box>
        </div>
        <div class="col">
          <fullstack-input-box [options]="{ label: 'Raggio *' }" [(ngModel)]="radius"></fullstack-input-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <fullstack-input-address-box
            [options]="{ label: 'Indirizzo Operativo su FR *' }"
            [(ngModel)]="frAddress"
            (ngModelChange)="frAddress = $event; findSuperior($event)"
          ></fullstack-input-address-box>
        </div>
        <!-- spostato il dato su fr -->
        <!-- <div class="col">
          <fullstack-input-select-box
            [ngModel]="parent"
            (ngModelChange)="parent = $event"
            [options]="{ label: 'WM *' }"
          >
            <sb-option [key]="'code'" [value]="i" *ngFor="let i of frStoreManagers | async">{{
              i.value
            }}</sb-option>
          </fullstack-input-select-box>
        </div> -->
      </div>
      <!-- in attesa di richiesta del cliente per attivarlo -->
      <!-- <div class="row mt-2">
        <div class="col">
          <fullstack-datepicker
            [ngModel]="assistantEmploymentDate"
            (ngModelChange)="assistantEmploymentDate = $event"
            [options]="{ label: 'Data assunzione *' }"
          ></fullstack-datepicker>
        </div>
      </div> -->
      <div class="row mt-2">
        <div class="col-12">
          <fullstack-input-textarea
            [options]="{ label: 'Note', rows: 2 }"
            [(ngModel)]="note"
          ></fullstack-input-textarea>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-sm-12">
          <fullstack-file-upload-new
            [options]="{ label: 'Documento di identità' }"
            [accept]="'application/pdf'"
            [multiple]="true"
            [fileList]="identityFiles"
            (onFilesSelect)="onAttIdentitySelect($event)"
            [storageUrl]="environment.apiGatewayURL + '/recruit/file-server/download'"
          >
            <ng-template file-row let-file>
              <div class="d-flex align-items-center">
                <div class="p-1 flex-fill">
                  <fullstack-input-box
                    [options]="{ label: 'Nome file' }"
                    [ngModel]="file.metadata.name || file.originalName"
                    (ngModelChange)="file.metadata.name = $event"
                    [ngModelOptions]="{ standalone: true }"
                  ></fullstack-input-box>
                </div>
              </div>
            </ng-template>
          </fullstack-file-upload-new>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-12">
          <fullstack-file-upload-new
            [options]="{ label: 'Codice fiscale' }"
            [accept]="'application/pdf'"
            [multiple]="true"
            [fileList]="fiscalFiles"
            (onFilesSelect)="onAttFiscalSelect($event)"
            [storageUrl]="environment.apiGatewayURL + '/recruit/file-server/download'"
          >
            <ng-template file-row let-file>
              <div class="d-flex align-items-center">
                <div class="p-1 flex-fill">
                  <fullstack-input-box
                    [options]="{ label: 'Nome file' }"
                    [ngModel]="file.metadata.name || file.originalName"
                    (ngModelChange)="file.metadata.name = $event"
                    [ngModelOptions]="{ standalone: true }"
                  ></fullstack-input-box>
                </div>
              </div>
            </ng-template>
          </fullstack-file-upload-new>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-12">
          <fullstack-file-upload-new
            [options]="{ label: 'Idoneità medica' }"
            [accept]="'application/pdf'"
            [multiple]="true"
            [fileList]="medicalFiles"
            (onFilesSelect)="onAttMedicalSelect($event)"
            [storageUrl]="environment.apiGatewayURL + '/recruit/file-server/download'"
          >
            <ng-template file-row let-file>
              <div class="d-flex align-items-center">
                <div class="p-1 flex-fill">
                  <fullstack-input-box
                    [options]="{ label: 'Nome file' }"
                    [ngModel]="file.metadata.name || file.originalName"
                    (ngModelChange)="file.metadata.name = $event"
                    [ngModelOptions]="{ standalone: true }"
                  ></fullstack-input-box>
                </div>
              </div>
            </ng-template>
          </fullstack-file-upload-new>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-12">
          <fullstack-file-upload-new
            [options]="{ label: 'Lettera di intenti' }"
            [accept]="'application/pdf'"
            [multiple]="true"
            [fileList]="commitmentFiles"
            (onFilesSelect)="onAttCommitmentSelect($event)"
            [storageUrl]="environment.apiGatewayURL + '/recruit/file-server/download'"
          >
            <ng-template file-row let-file>
              <div class="d-flex align-items-center">
                <div class="p-1 flex-fill">
                  <fullstack-input-box
                    [options]="{ label: 'Nome file' }"
                    [ngModel]="file.metadata.name || file.originalName"
                    (ngModelChange)="file.metadata.name = $event"
                    [ngModelOptions]="{ standalone: true }"
                  ></fullstack-input-box>
                </div>
              </div>
            </ng-template>
          </fullstack-file-upload-new>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="text-end">
        <div class="col">
          <button (click)="salva()" class="btn btn-secondary" [disabled]="false">Salva</button>
        </div>
        <div class="col mt-2" *permissions="['is_admin']">
          <button
            (click)="activate()"
            class="btn btn-secondary"
            [disabled]="
              !candidato ||
              candidato?.crmFrData?.crmFrId ||
              !username ||
              !workingEmail ||
              medicalFiles.lenght === 0 ||
              identityFiles.lenght === 0 ||
              fiscalFiles.lenght === 0 ||
              commitmentFiles.lenght === 0
            "
          >
            Attiva su FR
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class CandidatiDetailsFrWorkerInfoComponent implements OnChanges {
  environment = environment;
  medicalFiles: IFileServerModel[] = [];
  identityFiles: IFileServerModel[] = [];
  fiscalFiles: IFileServerModel[] = [];
  commitmentFiles: IFileServerModel[] = [];
  @Input() candidato: ICandidate;
  parent: string;
  senior: any;
  shop: string;
  username: string;
  workingEmail: string;
  stateOnFr: { isAvailable: boolean; busy: boolean } = { isAvailable: false, busy: false };
  frAddress: IAddress;
  frType: string;
  radius: number;
  note: string;
  shops: Observable<{ value: string; code: string }[]>;
  frStoreManagers: Observable<IFRSearchResult[]>;
  seniors: Observable<Pick<IFRSearchResult, 'code' | 'email' | 'value'>[]>;
  architectType = null;
  areaManager: string;
  dc: string;
  assistantEmploymentDate: Date;
  constructor(private candidateService: CandidatesServices) {}
  ngOnChanges(): void {
    const contact = this.candidato?.cache?.contact as IContactIndividual;
    this.parent = this.candidato?.crmFrData?.registration?.parent;
    this.shop = this.candidato?.crmFrData?.registration?.shop;
    this.username =
      this.candidato?.crmFrData?.registration?.username ||
      contact.firstName.trim().toLowerCase().replace(' ', '') +
        '.' +
        contact.lastName.trim().toLowerCase().replace(' ', '') ||
      null;
    this.workingEmail = this.candidato?.crmFrData?.registration?.workingEmail;
    this.frType = null;
    this.radius = this.candidato?.crmFrData?.registration?.radius;
    this.note = this.candidato?.crmFrData?.registration?.note;
    this.shops = this.candidateService.getFrShop$();
    this.frStoreManagers = this.candidateService.getFrSM$();
    this.frAddress = this.candidato?.crmFrData?.registration?.frAddress;
    this.senior = this.candidato?.crmFrData?.registration?.senior;
    this.assistantEmploymentDate = this.candidato?.crmFrData?.registration?.assistantEmploymentDate;
    if (this.candidato?.crmFrData?.registration?.medicalCertificate) {
      this.candidateService
        .getContract(this.candidato.crmFrData.registration.medicalCertificate)
        .subscribe((file) => (this.medicalFiles = [file]));
    }
    if (this.candidato?.crmFrData?.registration?.identityCard) {
      this.candidateService
        .getContract(this.candidato.crmFrData.registration.identityCard)
        .subscribe((file) => (this.identityFiles = [file]));
    }
    if (this.candidato?.crmFrData?.registration?.fiscalCodeDocument) {
      this.candidateService
        .getContract(this.candidato.crmFrData.registration.fiscalCodeDocument)
        .subscribe((file) => (this.fiscalFiles = [file]));
    }
    if (this.candidato?.crmFrData?.registration?.commitmentLetter) {
      this.candidateService
        .getContract(this.candidato.crmFrData.registration.commitmentLetter)
        .subscribe((file) => (this.commitmentFiles = [file]));
    }
  }

  salva() {
    const body: ICandidate = {
      ...this.candidato,
      crmFrData: {
        registration: {
          username: this.username,
          workingEmail: this.workingEmail,
          radius: this.radius,
          frAddress: this.frAddress,
          note: this.note,
          assistantEmploymentDate: this.assistantEmploymentDate,
          medicalCertificate: this.medicalFiles[0].id,
          identityCard: this.identityFiles[0].id,
          fiscalCodeDocument: this.fiscalFiles[0].id,
          commitmentLetter: this.commitmentFiles[0].id,
        },
      },
    };
    this.candidateService.savePartialFrWorker(body).subscribe();
  }
  activate() {
    const body: ICandidate = {
      ...this.candidato,
      crmFrData: {
        registration: {
          username: this.username,
          workingEmail: this.workingEmail,
          radius: this.radius,
          frAddress: this.frAddress,
          note: this.note,
          assistantEmploymentDate: this.assistantEmploymentDate,
          medicalCertificate: this.medicalFiles[0].id,
          identityCard: this.identityFiles[0].id,
          fiscalCodeDocument: this.fiscalFiles[0].id,
          commitmentLetter: this.commitmentFiles[0].id,
        },
      },
    };
    this.candidateService.activateFrWorker(body).subscribe();
  }
  onAttMedicalSelect(event) {
    const files: FileList = event.target.files;
    this.candidateService.addFile(files).subscribe((files: IFileServerModel<any>[]) => {
      this.medicalFiles = [...files, ...this.medicalFiles];
    });
  }
  onAttIdentitySelect(event) {
    const files: FileList = event.target.files;
    this.candidateService.addFile(files).subscribe((files: IFileServerModel<any>[]) => {
      this.identityFiles = [...files, ...this.identityFiles];
    });
  }
  onAttFiscalSelect(event) {
    const files: FileList = event.target.files;
    this.candidateService.addFile(files).subscribe((files: IFileServerModel<any>[]) => {
      this.fiscalFiles = [...files, ...this.fiscalFiles];
    });
  }
  onAttCommitmentSelect(event) {
    const files: FileList = event.target.files;
    this.candidateService.addFile(files).subscribe((files: IFileServerModel<any>[]) => {
      this.commitmentFiles = [...files, ...this.commitmentFiles];
    });
  }
  findSuperior(event: IAddress) {
    this.seniors = this.candidateService.getFrSeniorArchitect$(event.coords as [number, number]);
  }
}
