<div class="input-file-upload">
  <div class="card p-2 label-control border-bottom-0" *ngIf="options?.label">
    {{ options?.label }}
  </div>
  <p-fileUpload
    #fileUpload
    name="media"
    customUpload="false"
    [disabled]="disabled"
    [multiple]="multiple"
    [accept]="accept"
    [maxFileSize]="maxFileSize"
    (uploadHandler)="uploadHandler($event)"
  >
    <ng-template let-file pTemplate="file">
      <div class="p-fileupload-row ng-star-inserted">
        <div><i class="fas fa-file-image fa-3x"></i></div>
        <div>{{ file.name }}</div>
        <div>
          <button
            type="button"
            icon="pi pi-times"
            class="p-button p-component p-button-icon-only"
            (click)="removeSelected(file)"
          >
            <span class="p-button-icon pi pi-times" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <small class="p-error block" *ngIf="error"> {{ error }}</small>
      <div class="p-fileupload-row ng-star-inserted" *ngFor="let fileUploaded of files; let i = index">
        <div><i class="fas fa-file-image fa-3x"></i></div>
        <div>{{ fileUploaded }}</div>
        <div>
          <a
            href="{{ storageUrl + '/' + fileUploaded }}"
            icon="pi pi-download"
            class="p-button p-component p-button-icon-only me-2"
            target="_blank"
          >
            <span class="p-button-icon pi pi-download" aria-hidden="true"></span>
          </a>
          <button
            type="button"
            icon="pi pi-times"
            class="p-button p-component p-button-icon-only"
            (click)="removeUploaded(i)"
          >
            <span class="p-button-icon pi pi-times" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </ng-template>
  </p-fileUpload>
</div>
