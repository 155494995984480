import { Pipe, PipeTransform } from '@angular/core';
import { IDomainValue } from '@renovars/common';
import { map } from 'rxjs';
import { DomainValuesService } from '../../../services/domain-values.service';

@Pipe({ name: 'domainValue' })
export class DomainValuesPipe<T = unknown> implements PipeTransform {
  constructor(private domainValues: DomainValuesService) {}

  transform(value: string, domainValueType: IDomainValue<T>) {
    return this.domainValues.list().pipe(
      map((res) => res.find((d) => d.code.toString() == value && d.type == domainValueType)),
      map((dv) => dv?.label)
    );
  }
}

@Pipe({ name: 'domainValuesFilter' })
export class DomainValuesFilterPipe<T = unknown> implements PipeTransform {
  constructor() {}

  transform(domainValues: Array<IDomainValue<T>>, domainValueType: T, additionalFilter: number[]) {
    let resPipe = null;
    if (domainValues) {
      resPipe = domainValues.filter((d) => d.type === domainValueType);
    }
    if (additionalFilter && additionalFilter.length > 0) {
      resPipe = resPipe.filter((d) => !additionalFilter.includes(d.code));
    }
    return resPipe;
  }
}
